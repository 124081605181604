<template>
  <div class="footer-wrapper relative bottom-0 mt-20">
    <footer id="footer" class="shadow p-4">
      <div class="flex justify-center items-center gap-5">
        <div class="flex-shrink-0">
          <router-link :to="{ name: 'home_page' }">
            <img
              class="w-24"
              src="@/assets/images/logo.png"
              alt="soildrops-Logo"
            />
          </router-link>
        </div>
        <span class="text-sm">&copy; {{ new Date().getFullYear() }} soildrops</span>
      </div>
    </footer>
  </div>
</template>
